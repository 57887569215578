@font-face {
    font-family: "Sukhumvit Set";
    src: url(assets/fonts/sukhumvit-set_text-webfont.woff);
    font-weight: 400;
}
@font-face {
    font-family: "Sukhumvit Set";
    src: url(assets/fonts/sukhumvit-set_medium-webfont.woff);
    font-weight: 500;
}
@font-face {
    font-family: "Sukhumvit Set";
    src: url(assets/fonts/sukhumvit-set_semibold-webfont.woff);
    font-weight: 600;
}
@font-face {
    font-family: "Sukhumvit Set";
    src: url(assets/fonts/sukhumvit-set_bold-webfont.woff);
    font-weight: 700;
}

$font-color-black: #011a40;

body {
    margin: 0;
    font-family: "Sukhumvit Set", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    background: linear-gradient(180deg, #011a40 0%, #3c557a 100%);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
}

.ant-form-item-explain.ant-form-item-explain-connected {
    margin-top: 4px;
}

input.ant-input,
.ant-select:not(.ant-select-disabled) .ant-select-selection-item,
.ant-checkbox-wrapper {
    font-style: normal;
    font-weight: 400;
    color: $font-color-black;
    /* font-size: 16px;
  line-height: 22px; */
}

.ant-picker .ant-picker-input {
    flex-direction: row-reverse;
    column-gap: 4px;
}

.ant-select:not(.ant-select-disabled) .ant-select-arrow {
    color: $font-color-black;
}

.ant-form:not(.ant-form-hide-required-mark) .ant-form-item {
    margin-bottom: 16px;

    .ant-form-item-label label,
    .ant-radio-wrapper-in-form-item span,
    .ant-checkbox-wrapper,
    .ant-form-item-control {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: $font-color-black;
    }

    .ant-form-item-label
        > .ant-form-item-required:not(.ant-form-item-required-mark-optional) {
        &::before {
            display: none;
        }
        &::after {
            content: "*";
            display: inline-block;
        }
    }
}

.ant-message-notice {
    &.custom-success .ant-message-notice-content {
        background: #b6f6c8;
    }
    &.custom-error .ant-message-notice-content {
        background: #ffcdce;
    }

    &.custom-success,
    &.custom-error {
        .ant-message-notice-content {
            padding: 20px 30px;
            box-shadow: 0px 4px 44px -17px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            width: 80%;
            max-width: 800px;

            .anticon {
                display: none;
            }

            .content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 160%;
                color: #000000;

                .anticon.close-btn {
                    display: block;
                    color: #000000;
                    cursor: pointer;
                }
            }
        }
    }
}
